<template>
  <div class="companyList">
    <div
      class="title"
      style="justify-content: space-between; padding-right: 5px"
    >
      <p id="zjxw">新上市公司</p>
    </div>
    <div class="newsItemCrew">
      <div
        class="companyListSourceItem"
        v-for="i in apiDataCompanyList"
        :key="i.full_name"
        @click="chooseCompany(i)"
      >
        <div class="companyListSourceItem-name">
          <div class="newsTitle">
            <p ref="newsTitleP" class="newsTitle-p">
              ▪ {{ i.short_name }}
              <span class="companyListSourceItem-tagBlue">{{
                boards_map[i.board_id - 1]
              }}</span>
              <span class="companyListSourceItem-tag2">{{ i.code }}</span>
              <span class="companyListSourceItem-tagBlue">{{ i.industry1 }}</span>
            </p>
          </div>
        </div>
        <span class="companyListSourceItem-time">{{
          i.market_time.replace("T00:00:00Z", "")
        }}</span>
        <span class="companyListSourceItem-time">{{ i.full_name }}</span>
        <!-- <span class="companyListSourceItem-time">{{ i.address }}</span> -->
        <!-- 
        <span v-if="i.source" class="companyListSourceItem-source">{{
          i.source == "" ? "未知" : i.source
        }}</span>
        <span v-else class="companyListSourceItem-source">{{ i.web_source }}</span> -->
        <!-- <span class="companyListSourceItem-tagBlue" v-if="i.event_classify">{{
          i.event_classify
        }}</span> -->
        <!-- <span v-for="(arr,idx) in i.title_classify_array" :key="idx">
          <span class="companyListSourceItem-tagBlue" v-if="arr == 1">{{
            type_map[idx]
          }}</span>
        </span>
        <span v-for="(tag,index) in i.title_classify_tags" :key="tag+index.toString()">
          <span class="companyListSourceItem-tag2" v-if="index%2==0">{{
            tag
          }}</span>
        </span>
        <span v-for="(tag,index) in i.title_classify_tags" :key="tag+index.toString()">
          <span class="companyListSourceItem-tagRed" v-if="index%2!=0">{{
            tag
          }}</span>
        </span>
         <el-button type="danger" size="mini" style="margin-left:10px;margin-right:10px;float:right;" @click.stop="removeNews(i.title)">删除</el-button>
        <el-button type="primary" size="mini" style="margin-left:0px;float:right" @click.stop="changeNewsPolarity(i)">修改极性</el-button> -->
        <!-- <span
          :class="
            Math.abs(o.positive_score) > Math.abs(o.negative_score)
              ? 'companyListSourceItem-tag'
              : 'companyListSourceItem-tagRed'
          "
          v-for="o in i.company_opinion_list"
          :key="o.company_name"
          >{{
            o.company_name +
            "：" +
            "正" +
            o.positive_score +
            " / " +
            "负" +
            o.negative_score
          }}</span
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "companyList",
  props: {
    apiDataCompanyList: Array,
  },
  data() {
    return {
      newsType: "",
      newsDetailVisible: false,
      // newsSearchCompany: "",
      requestParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        source_type: "0",
        company: "",
      },
      boards_map: [
        "深圳主板",
        "上海主板",
        "深圳中小板",
        "深圳创业板",
        "上海科创板",
        "新三板",
      ],
      newsDetail: {
        title: "",
        release_time: "",
        source: "",
        content: "",
        url: "",
        web_source: "",
      },
      companyList: [
        // {
        //   title: "比亚迪发布新一代ev：汉",
        //   polarity: 1,
        //   release_time: "2020-04-28",
        //   source: "baidu",
        //   web_source: "36kr",
        //   event_classify: "产品新闻",
        //   company_opinion_list: [
        //     {
        //       company_name: "比亚迪",
        //       positive_score: "10",
        //       negative_score: "1"
        //     }
        //   ]
        // }
      ],
    };
  },
  methods: {
    // 选择公司
    chooseCompany(i) {
      console.log(i)
      EventBus.$emit("choose_new_company", i.id)
      EventBus.$emit("company_id", i.id)
      EventBus.$emit("short_name", i.short_name)
    },
    // 删除新闻
    removeNews(title) {
      console.log("title", title);
      let params = {
        news_title: title,
      };
      this.$confirm("此操作将删除该新闻, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.removeNews(params).then((res) => {
            if (res.status == 200) {
              this.$message.success("删除成功");
              EventBus.$emit("remove_news", 1);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改新闻极性
    changeNewsPolarity(i) {
      // 若新闻无极性，修改为正面
      let polarity = i.polarity ? (i.polarity == 1 ? 0 : 1) : 1;
      let params = {
        news_title: i.title,
        polarity: polarity,
      };
      this.$confirm(
        "您确定要修改该新闻极性吗?（备注: 若该新闻无极性，修改为正面）",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          api.changeNewsPolarity(params).then((res) => {
            if (res.status == 200) {
              this.$message.success("修改成功");
              EventBus.$emit("change_polarity", 1);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 监听事件分类统计点击
    listenNewsFilter() {
      EventBus.$on("news_filter", (data) => {
        this.newsType = data;
      });
    },
    // 监听company_id改变
    listenCompanyId() {
      EventBus.$on("company_id", (data) => {
        this.newsType = "";
      });
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        this.newsType = "";
      });
    },
    // 重置筛选
    reset() {
      EventBus.$emit("reset", 1);
      // EventBus.$emit("news_filter", "")
      this.newsType = "";
    },
    // 前往原网页
    toOriginPage() {
      window.open(this.newsDetail.url, "_blank");
    },
    // 显示详情
    showDetail(info) {
      setInterval;
      if (info.content) {
        this.newsDetail = info;
        this.newsDetailVisible = true;
      } else {
        window.open(info.url, "_blank");
      }
    },
  },
  mounted() {
    this.listenNewsFilter();
    this.listenReset();
    this.listenCompanyId();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.companyList {
  font-size: 17px;
  margin-top: 5px !important;
  // margin-left: 10px;
  width: 100%;
  border-radius: 7px;
  .searchParam {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-size: 14px;
    font-weight: normal;
    color: #409eff;
    p {
      margin-right: 10px;
    }
  }
  .newsItemCrew {
    overflow-y: scroll;
    max-height: 70vh !important;
  }
  .companyListSourceItem {
    cursor: pointer;
    font-size: 16px;
    padding-left: 15px;
    margin-bottom: 10px;
    // padding-bottom: 10px;
    border-bottom: 1px solid #f5f4f9;
    // &-name {
    //   display: flex;
    //   justify-content: space-between;
    // }
    &-tag,
    &-tag2,
    &-tagBlue,
    &-tagRed {
      display: inline-block;
      margin-left: 10px;
      margin-bottom: 5px;
      font-size: 12px;
      padding: 1px 3px 1px 3px;
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
      border-radius: 4px;
      border: 1px solid #d9ecff;
    }
    &-tag2 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }
    &-tagBlue {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409eff;
    }
    &-tagRed {
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f56c6c;
    }
    &-time,
    &-source,
    &-webSource {
      display: inline-block;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-left: 10px;
    }
    &-source {
      margin-left: 10px;
    }
    &-webSource {
      margin-left: 20px;
    }
    &-website {
      color: rgb(49, 135, 216);
      font-size: 14px;
      margin-top: 5px;
      word-break: break-all;
      margin-right: 10px;
    }
  }
}
</style>
