<template>
  <div
    class="companyGraph"
    v-loading.fullscreen.lock="
      barLoading |
        cloudLoading |
        linkLoading |
        tagLoading |
        pdfLoading |
        infoLoading |
        productLoading
    "
  >
    <div class="companyGraph-top" style="display: flex" v-if="!company_id">
      <industry-bar :apiDataBarChart="apiDataBarChart"></industry-bar>
      <!-- <type-pie :apiDataPieChart="apiDataPieChart2" v-show="company_id_selected==0"></type-pie> -->
      <news-keyword-cloud
        style="padding-top: 15px; width: 30%; margin-left: 1%"
        :apiDataLineChart="wordCloudData"
      ></news-keyword-cloud>
    </div>
    <div class="companyGraph-list" v-if="!company_id">
      <company-list :apiDataCompanyList="apiDataCompanyList"></company-list>
      <div class="showMore" @click="showMore">
        <p>{{ showMoreText }}</p>
      </div>
    </div>
    <el-collapse
      v-if="company_id"
      class="collapseBox"
      v-model="collapseActiveNames"
      @change="handleCollapse"
    >
      <el-collapse-item title="▪ 产业标签" name="1">
        <div class="tagBox">
          <!-- <div class="tagBlue" v-for="i in tagList" :key="i">{{ i }}</div> -->
          <el-tag
            class="tagBox-tag"
            size="small"
            v-for="i in tagList"
            :key="i"
            @click="showIndustryNews(i)"
            >{{ i }}</el-tag
          >
        </div>
        <div class="tagBox">
          <!-- <div class="sourceItem-tag" v-for="i in productList" :key="i">{{ i }}</div> -->
          <el-tag
            class="tagBox-tag"
            size="small"
            v-for="i in productList"
            :key="i"
            type="success"
            @click="showIndustryNews(i)"
            >{{ i }}</el-tag
          >
        </div>
        <div class="tagBox">
          <!-- <div class="sourceItem-tag2" v-for="i in industryPaquList" :key="i">{{ i }}</div> -->
          <el-tag
            class="tagBox-tag"
            size="small"
            v-for="i in industryPaquList"
            :key="i"
            type="warning"
            @click="showIndustryNews(i)"
            >{{ i }}</el-tag
          >
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 产业链信息" name="2">
        <link-echarts
          :apiDataGraphChart="chartDataLink"
          :shortName="chartLinkShortName"
          :onlySub="onlySub"
        ></link-echarts>
      </el-collapse-item>
      <el-collapse-item title="▪ 财务信息" name="3">
        <div class="bombBox-content_v2" v-show="lineChartFinancialVisible">
          <financial-echarts
            ref="financialEcharts"
            :apiDataLineChart="chartDataFinancial"
          ></financial-echarts>
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 营收构成" name="4">
        <div class="bombBox-content_v2" v-show="lineChartProductVisible">
          <product-echarts
            ref="productEcharts"
            :apiDataLineChart="chartDataProduct"
            :apiYearList="chartProductYearList"
          ></product-echarts>
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 股东信息" name="5">
        <div class="bombBox-content_v2" style="margin-bottom: 10px">
          <table
          class="holderTable"
            border="1"
            cellpadding="0"
            cellspacing="0"
            v-if="detailTrTopVal.length != 1"
          >
            <tbody>
              <tr v-for="t in detailTrTopVal" :key="t[0]">
                <td v-for="(d, index) in t" :key="d + index.toString()">
                  {{ d }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-collapse-item>
      <el-collapse-item title="▪ 专利信息" name="6">
        <div
         v-loading="patentLoading"
          class="bombBox-content_v2 flexBox"
          style="padding-bottom: 20px"
          v-if="patentParams.company_id != ''"
        >
          <patent-echarts
            :apiDataLineChart="patentEchartsData"
          ></patent-echarts>
          <keyword-cloud :apiDataLineChart="wordCloudData2"></keyword-cloud>
        </div>
        <patent-list :apiDataLineChart="patentListData"></patent-list>
        <div class="showMore" @click="showMorePatent">
          <p>{{ showMoreText }}</p>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-dialog width="80%" :visible.sync="newsDialogVisible" class="newsDialog">
      <div v-loading="newsLoading | typeLoading | polarityLoading">
        <div style="display: flex">
          <news-type-bar :apiDataBarChart="newsTypeData"></news-type-bar>
          <polarity-pie :apiDataPieChart="polarityData"></polarity-pie>
        </div>
        <news-list
          :apiDataNewsList="newsdetailList"
          :hasKeyword="newsParams.keyword"
        ></news-list>
        <div class="showMore" @click="showMoreNews">
          <p>{{ showMoreText }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import FinancialEcharts from "../components/FinancialEcharts";
import ProductEcharts from "../components/ProductEcharts";
import LinkEcharts from "../components/LinkEcharts";
import IndustryBar from "../components/IndustryBar";
import NewsKeywordCloud from "../components/NewsKeywordCloud";
import CompanyList from "../components/CompanyList";
import NewsList from "../components/NewsList.vue";
import PolarityPie from "@/components/PolarityPie.vue";
import NewsTypeBar from "@/components/NewsTypeBar.vue";
import PatentEcharts from "../components/PatentEcharts.vue";
import KeywordCloud from "../components/KeywordCloud.vue";
import PatentList from "../components/PatentList.vue";

export default {
  name: "CompanyGraph",
  components: {
    FinancialEcharts,
    ProductEcharts,
    LinkEcharts,
    IndustryBar,
    NewsKeywordCloud,
    CompanyList,
    NewsList,
    PolarityPie,
    NewsTypeBar,
    PatentEcharts,
    KeywordCloud,
    PatentList,
  },
  data() {
    return {
      patentLoading: false,
      barLoading: false,
      cloudLoading: false,
      linkLoading: false,
      tagLoading: false,
      pdfLoading: false,
      infoLoading: false,
      productLoading: false,
      newsLoading: false,
      newsDialogVisible: false,
      newsTypeData: {
        legend: [],
        data: [],
      },
      patentEchartsData: [],
      wordCloudData: [],
      wordCloudData2: {},
      patentListData: [],
      patentListTotal: 0,
      polarityData: [],
      polarityLoading: false,
      typeLoading: false,
      newsdetailList: [],
      showMoreText: "加载更多",
      onlySub: false, // 是否只拥有子公司
      apiDataCompanyList: [],
      collapseActiveNames: ["1", "2", "3", "4", "5", "6"],
      chartDataFinancial: [],
      chartDataProduct: {},
      chartProductYearList: [],
      detailTrTopVal: [],
      chartLinkShortName: "",
      lineChartFinancialVisible: false,
      lineChartProductVisible: false,
      apiDataBarChart: {
        legend: [],
        data: [],
      },
      company_id: "",
      requestParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        source_type: "1", // 财报类型选择:0 => 半年度，1 => 年度
      },
      pageTotal: 0,
      tagList: [],
      productList: [],
      industryPaquList: [],
      short_name: "",
      chartDataLink: {},
      newsList: [],
      companyNewsList: [],
      newsParams: {
        page: 1,
        page_size: 10,
        company: "",
        permissions: "",
        news_type: "",
        polarity: "",
        keyword: "",
      },
      companyParams: {
        page_size: 10,
        page: 1,
      },
      patentParams: {
        page: 1,
        page_size: 10,
        company_id: "",
        permissions: ""
      },
    };
  },
  mounted() {
    cookie.setCookie("serverValue", "企业图谱");
    this.newsParams.permissions = cookie.getCookie("permissions");
    this.patentParams.permissions = cookie.getCookie("permissions")
    this.listenNewsFilter()
    this.listenShortName();
    this.listenCompanyId();
    this.listenReset();
    this.listenResetSelect()
    this.listenSearchIndustryNews();
    this.getIndustryCount();
    this.getIndustryKeyword();
    this.getNewCompany();
    // this.getPatentList()
    // this.getPatentCountAndKeyword()
  },
  methods: {
    // 获取专利统计和关键词云
    getPatentCountAndKeyword() {
      let params = {
        company_id: this.company_id,
      };
      this.patentLoading = true
      api.getCompanyBaseInfo(params).then((res) => {
        console.log("专利", res);
        this.patentEchartsData = res.data[0].year_count_list;
        this.wordCloudData2 = res.data[0].patent_keywords;
        this.patentLoading = false
      }).catch(err => {
        this.patentLoading = false
      })
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;\
      this.patentParams.company_id = this.company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach(i => {
          this.patentListData.push(i)
        });
        // this.patentLoading = false;
      });
    },
    // 监听搜索产业新闻
    listenSearchIndustryNews() {
      EventBus.$on("searchIndustryNews", (data) => {
        this.showIndustryNews(data);
      });
    },
    // 获取新闻分类统计(产业标签)
    getNewsTypeCountIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions"),
        company_id: "",
        keyword: this.newsParams.keyword,
        industry: ""
      };
      this.newsTypeData.legend = [];
      this.newsTypeData.data = [];
      this.typeLoading = true;
      api.getNewsTypeCountIndustry(params).then((res) => {
        console.log("五大类", res);
        this.newsTypeData.legend = [];
        this.newsTypeData.data = [];
        res.data.forEach((i) => {
          this.newsTypeData.legend.push(i.name);
          this.newsTypeData.data.push(i.value);
        });
        console.log("xx", this.newsTypeData);
        this.typeLoading = false;
      });
    },
    // 获取新闻极性统计(产业标签)
    getNewsPolarityCountIndustry() {
      let params = {
        permissions: cookie.getCookie("permissions"),
        keyword: this.newsParams.keyword,
        industry: "",
        news_type: this.newsParams.news_type
      };
      this.polarityLoading = true;
      api.getNewsPolarityCountIndustry(params).then((res) => {
        console.log("极性", res);
        this.polarityData = res.data;
        this.polarityLoading = false;
      });
    },
    // 加载更多新闻
    showMoreNews() {
      this.newsParams.page += 1;
      console.log("page", this.newsParams.page);
      if (this.newsParams.page <= 100) {
        this.getNews();
      } else {
        // this.showMoreText = "已无更多";
      }
    },
    // 加载更多专利
    showMorePatent() {
      this.patentParams.page += 1
      console.log("page", this.patentParams.page)
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    // 获取新闻
    getNews() {
      // this.loading = true;
      console.log("获取新闻1");
      this.newsLoading = true;
      api.getNews(this.newsParams).then((res) => {
        console.log("news", res);
        // this.newsList = res.data;
        res.data.forEach((i) => {
          i.title_classify_tags.forEach((t, index) => {
            if (
              index > 0 &&
              index % 2 == 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 2]
            ) {
              i.title_classify_tags[index] = "";
            }
            if (
              index % 2 != 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 1]
            ) {
              i.title_classify_tags[index] = "";
            }
          });
          this.newsdetailList.push(i);
        });
        // this.loading = false;
        this.newsLoading = false;
        console.log("获取新闻完毕1");
        console.log(this.newsdetailList);
      });
    },
    // 展示产业标签相关新闻
    showIndustryNews(i) {
      this.newsdetailList = [];
      this.newsDialogVisible = true;
      console.log(i);
      this.newsParams.page = 1;
      this.newsParams.keyword = i;
      this.getNews();
      this.getNewsTypeCountIndustry();
      this.getNewsPolarityCountIndustry();
    },
    handleCollapse(val) {
      console.log("collapse", val);
      if (val.indexOf("3") != -1) {
        EventBus.$emit("financial_rerender", 1);
      }
      if (val.indexOf("4") != -1) {
        EventBus.$emit("product_rerender", 1);
      }
    },
    // 加载更多
    showMore() {
      this.companyParams.page += 1;
      console.log("page", this.companyParams.page);
      if (this.companyParams.page <= 100) {
        this.getNewCompany();
      } else {
        this.showMoreText = "已无更多";
      }
    },
    handlePageChange() {},
    // 获取新上市公司列表
    getNewCompany() {
      api.getNewCompany(this.companyParams).then((res) => {
        console.log("新上市公司", res);
        // this.apiDataCompanyList = res.data
        res.data.forEach((i) => {
          this.apiDataCompanyList.push(i);
        });
      });
    },
    // 监听重置reset
    listenReset() {
      EventBus.$on("reset", (data) => {
        this.company_id = "";
        this.getIndustryKeyword();
        this.getIndustryCount();
      });
    },
    // 监听重置筛选
    listenResetSelect() {
      EventBus.$on("resetSelect", (data) => {
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = ""
        this.tabsValue = "1"
        console.log("监听重置筛选")
        this.newsList = [];
        // this.newsParams.company = ""
        this.newsParams.page = 1;
        this.newsParams.polarity = "";
        this.newsParams.news_type = "";
        this.getNews();
        // this.getNewsTypeCountIndustry();
        this.getNewsPolarityCountIndustry()
      });
    },
    // 监听新闻筛选
    listenNewsFilter() {
      EventBus.$on("news_filter", (data) => {
        console.log("监听新闻筛选")
        this.newsdetailList = [];
        this.newsParams.page = 1;
        this.newsParams.news_type = data;
        this.getNews();
        this.getNewsPolarityCountIndustry();
      });
    },
    // 获取产业关键词
    getIndustryKeyword() {
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        permissions: cookie.getCookie("permissions"),
      };
      this.cloudLoading = true;
      api.getIndustryKeyword(params).then((res) => {
        console.log("产业关键词", res);
        this.wordCloudData = res.data;
        this.cloudLoading = false;
      }).catch(err => {
         this.cloudLoading = false;
      })
    },
    // 获取行业构成分布
    getIndustryCount() {
      let params = {
        // permissions: sessionStorage.getItem("permissions"),
        permissions: cookie.getCookie("permissions"),
      };
      this.barLoading = true;
      this.apiDataBarChart.legend = [];
      this.apiDataBarChart.data = [];
      api.getIndustryCount(params).then((res) => {
        console.log("行业统计", res);
        // this.apiDataPieChart = res.data;
        res.data.forEach((i) => {
          let name = "";
          if (i.name.length > 4) {
            name = i.name.substring(0, 4) + "...";
          } else {
            name = i.name;
          }
          this.apiDataBarChart.legend.push(name);
          this.apiDataBarChart.data.push(i.value);
        });
        this.barLoading = false;
      });
    },
    // 点击公司信息tab
    companyInfoTabClick(val) {
      console.log("点击公司信息tab", val.name);
      console.log(this.requestParams.source_type);
      this.requestParams.page = 1;
      if (val.name == 0) {
        this.getPdfSemiannual();
      } else if (val.name == 1) {
        this.getPdfAnnual();
      } else if (val.name == 2) {
        this.getListedNews(1);
      } else if (val.name == 3) {
        this.getPdfQuarter();
      } else if (val.name == 4) {
        this.getPdfAnnualFull();
      } else if (val.name == 5) {
        this.getPdfSemiannualFull();
      } else if (val.name == 6) {
        this.getPdfProspectus();
      }
    },
    // 前往原始新闻页面
    toOriginNews(url) {
      window.open(url, "_blank");
    },
    // 获取产业链上下游
    async getLink() {
      let params = {
        company_id: this.company_id,
      };
      this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          this.chartLinkShortName = this.short_name;
          this.linkLoading = false;
        })
        .catch((err) => {
          this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 修改(删除)产业标签
    handleClose(value, type) {
      let tag_type_tmp = "";
      if (type == 0) {
        tag_type_tmp = "product_detail";
      } else if (type == 1) {
        tag_type_tmp = "product";
      } else if (type == 2) {
        tag_type_tmp = "industry_paqu";
      }
      let params = {
        tag_type: tag_type_tmp,
        tag_value: value,
        company_id: this.company_id,
      };
      this.$confirm("确定删除该标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api.changeProductTag(params).then((res) => {
            if (res.status == 200) {
              this.$message.success(res.msg);
              this.getProductTag();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 获取公司产业标签
    getProductTag() {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: this.company_id,
      };
      this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          // res.data.product.forEach((i) => {
          //   if (i != "") {
          //     this.productList.push(i);
          //   }
          // });

          // 正式环境
          res.data.product_list.forEach(i => {
            if (i!=="") {
              this.industryPaquList.push(i)
            }
          })
          console.log("indexof");
          this.industryPaquList = [...new Set(this.industryPaquList)];
         // 正式环境

          // if (res.data.product_detail) {
          //   this.tagList = res.data.product_detail.split("；");
          //   this.tagList.pop();
          //   this.tagList = [...new Set(this.tagList)];
          // }
          // if (res.data.industry_paqu) {
          //   this.industryPaquList = res.data.industry_paqu.split("；");
          //   this.industryPaquList.pop();
          // }

          this.tagLoading = false;
        })
        .catch((err) => {
          this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    // 监听公司简称变化
    listenShortName() {
      EventBus.$on("short_name", (data) => {
        this.short_name = data;
      });
    },
    // 监听公司company_id变化
    listenCompanyId() {
      EventBus.$on("company_id", (data) => {
        this.patentListData = []
        this.company_id = data;
        this.patentParams.company_id = data
        this.clickCompanyInfo();
        this.getPdfAnnual();
        this.getProductTag();
        this.getLink();
        this.getPatentList()
        this.getPatentCountAndKeyword()
      });
    },
    // 获取季度报告
    getPdfQuarter() {
      let params = {
        company_id: this.company_id,
        page: this.requestParams.page,
        page_size: this.requestParams.page_size,
      };
      this.pdfLoading = true;
      api
        .getPdfQuarter(params)
        .then((res) => {
          console.log("季度报告", res);
          this.newsList = res.data;
          // console.log("最新的newsList", this.newsList[0])
          this.pageTotal = res.total;
          this.pdfLoading = false;
        })
        .catch((err) => {
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取招股书
    getPdfProspectus() {
      let params = {
        company_id: this.company_id,
        page: this.requestParams.page,
        page_size: this.requestParams.page_size,
      };
      this.pdfLoading = true;
      api
        .getPdfProspectus(params)
        .then((res) => {
          console.log("半年度报告", res);
          this.newsList = res.data;
          // console.log("最新的newsList", this.newsList[0])
          this.pageTotal = this.newsList.length;
          this.pdfLoading = false;
        })
        .catch((err) => {
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取半年度报告
    getPdfSemiannual() {
      let params = {
        company_id: this.company_id,
        page: this.requestParams.page,
        page_size: this.requestParams.page_size,
      };
      this.pdfLoading = true;
      api
        .getPdfSemiannual(params)
        .then((res) => {
          console.log("半年度报告", res);
          this.newsList = res.data;
          // console.log("最新的newsList", this.newsList[0])
          // this.pageTotal = this.newsList.length;
          this.pageTotal = res.total;
          this.pdfLoading = false;
        })
        .catch((err) => {
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取半年度报告全文
    getPdfSemiannualFull() {
      let params = {
        company_id: this.company_id,
        page: this.requestParams.page,
        page_size: this.requestParams.page_size,
      };
      this.pdfLoading = true;
      api
        .getPdfSemiannualFull(params)
        .then((res) => {
          console.log("半年度报告全文", res);
          this.newsList = res.data;
          // console.log("最新的newsList", this.newsList[0])
          this.pageTotal = res.total;
          this.pdfLoading = false;
        })
        .catch((err) => {
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取年度报告全文
    getPdfAnnualFull() {
      let params = {
        company_id: this.company_id,
        page: this.requestParams.page,
        page_size: this.requestParams.page_size,
      };
      this.pdfLoading = true;
      api
        .getPdfAnnualFull(params)
        .then((res) => {
          console.log("年度报告全文", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          this.pageTotal = res.total;
          this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取年度报告
    getPdfAnnual() {
      let params = {
        company_id: this.company_id,
        page: this.requestParams.page,
        page_size: this.requestParams.page_size,
      };
      this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          this.pageTotal = res.total;
          this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 点击详情信息
    async clickCompanyInfo() {
      // this.companyInfoVisible = true;
      console.log("当前公司id", this.company_id);
      let params = {
        company_id: this.company_id,
      };
      this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          if (res.data.length != 0) {
            this.lineChartFinancialVisible = true;
          } else {
            this.lineChartFinancialVisible = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
        if (res.year_list.length != 0) {
          this.lineChartProductVisible = true;
        } else {
          this.lineChartProductVisible = false;
        }
      });
      this.$nextTick(() => {
        this.loading = false;
      });
    },
  },
  beforeDestroy() {
    EventBus.$off("short_name");
    EventBus.$off("company_id");
    EventBus.$off("reset");
    EventBus.$off("resetSelect")
    EventBus.$off("searchIndustryNews");
    EventBus.$off("news_filter")
  },
};
</script>

<style lang="scss">
.companyGraph {
  // margin-top: 52px;
  width: 99.8%;
  // height: 20vw;
  // margin-left: 5px;
  margin-top: 5px;
  // background-color: white;
  border-radius: 6px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  padding-bottom: 5px;
  // margin-bottom: 5px;
  // padding-top: 10px;
  &-top {
    background-color: white;
    border-radius: 6px;
  }
  &-list {
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }
  .tagBox {
    &-tag {
      margin-right: 7px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
  .title {
    font-size: 17px;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsDialog {
    .el-dialog__header {
      padding: 0;
    }
  }
}
</style>
